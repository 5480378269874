import type { User } from '@supabase/supabase-js'
import type { Database } from "~/types/database.types"

const userInfos = ref<Database['public']['Tables']['user_infos']['Row'] | null>(null)

/**
 * Fetch the informations about the user
 */
export async function useUserInfos(): Promise<{
  data: Ref<Database['public']['Tables']['user_infos']['Row'] | null>,
  refresh: () => Promise<any>
}> {
  // Needed composables
  const supabaseClient = useSupabaseClient<Database>()
  const user: Ref<User | null> = useSupabaseUser()
  if (!user.value || !user.value.id) {
    console.log(user.value)
    throw useCustomError({
      error: new AuthError(AuthErrorCodes.NOT_LOGGED_IN),
      data: user.value,
      reportToSentry: true,
      reportToUser: true,
    })
  }

  // Function to update the user infos
  async function updateUserInfos() {
    // Updating the user infos
    const { data, error } = await supabaseClient.from('user_infos').select('*').single()

    // Updating the cache data
    userInfos.value = data
    
    // Managing the error
    if (error !== null && import.meta.client) {
      useCustomError({
        error: new DatabaseError(DatabaseErrorCodes.SELECT_USER_INFOS_IMPOSSIBLE),
        cause: new Error(error.message),
        reportToSentry: true,
        reportToUser: true
      })
    }
  }

  // Updating the user infos if it's the first call of the composable
  if (user.value === null) {
    await updateUserInfos()
  }

  // Returning the user ib statements status
  // @ts-expect-error
  return {
    data: userInfos,
    refresh: updateUserInfos
  }
}